import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import withRouter from "../hooks/withRouter";
import AppRoutes from "./routes";
import Headermain from "../header";
import { Home } from "../pages/home";
import { Portfolio } from "../pages/portfolio";
import { ContactUs } from "../pages/contact";
import { Divider } from "../divider";
import { About } from "../pages/about";
import { Socialicons } from "../components/socialicons";
import "./App.css";
import "particles.js/particles";

export default function App() {
  useEffect(() => {
    window.particlesJS.load("particles-js", "/particles.json", function () {
      console.log("particles.js loaded - callback");
    });
  }, []);

  return (
    <div>
      <div
        id="particles-js"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          zIndex: -1, // Ensures it stays behind all other content
        }}
      ></div>
      <Headermain />
      <Home />
      <Portfolio />

      <About />
      <ContactUs />
    </div>
  );
}
//   return (

//     <div id="particles-js" style={{ position: 'relative', width: '100%', height: '100%' }}>
//     <Headermain />
//     <Home />
//   </div>
//     <div>
//       <Headermain />
//       <Home />

//       {/* <Divider /> */}
//       <Portfolio />
//       <ContactUs />

//       {/* <Home /> */}
//     </div>
//     // <Router basename={process.env.PUBLIC_URL}>
//     //   <Headermain />
//     //   <AppRoutes />
//     // </Router>
//   );
// }
