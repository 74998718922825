const logotext = "Jonny Ma";
const meta = {
  title: "Jonathan Ma",
  description:
    "I’m Jonathan Ma, a Mechanical Engineering student at the Uniersity of Michigan.",
};

const introdata = {
  title: "Jonathan Ma",
  label: "Robotics & Mechanical Engineer",
  your_img_url: "https://i.imgur.com/bNnIOx5.jpeg", //"https://imgur.com/KIfpSB7.jpg",
  //"https://i.imgur.com/7xVwKHw.jpg",
  //caption: "Check Out My Projects!",
};

const dataabout = {
  title: "About myself",
  aboutme:
    "I'm a Robotics Engineer with a focus on the intersection of mechanical systems, machine learning, and control theory. I'm passionate about leveraging machine learning and advanced algorithms to enhance the performance and autonomy of robotic systems. My interests lie in optimizing mechanical design, implementing predictive models, and integrating sensing technologies to create intelligent and adaptive systems that push the boundaries of robotics and automation.",
};

const contactConfig = {
  YOUR_EMAIL: "jmaengr@gmail.com",
  YOUR_FONE: "(734) 510-0446",
  YOUR_LINKEDIN: "https://www.linkedin.com/in/jonny-ma/", // Add LinkedIn URL here

  description: "Please feel free to reach out or send a message! ",
  // creat an emailjs.com account
  // check out this tutorial https://www.emailjs.com/docs/examples/reactjs/
  YOUR_SERVICE_ID: "service_4wyh5ft",
  YOUR_TEMPLATE_ID: "template_s1b1zji",
  YOUR_USER_ID: "gS6RRol1H5dqNFYhP",
};

const socialprofils = {
  github: "https://github.com",
  facebook: "https://facebook.com",
  linkedin: "https://linkedin.com",
  twitter: "https://twitter.com",
};
export { meta, dataabout, introdata, contactConfig, socialprofils, logotext };
