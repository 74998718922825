import React, { useEffect } from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { introdata, meta } from "../../content_option";
import { BsWrenchAdjustable } from "react-icons/bs";

export const Home = () => {
  return (
    <HelmetProvider>
      <section id="home" className="home">
        <Helmet>
          <meta charSet="utf-8" />
          <title> Jonathan Ma</title>
          <meta name="description" content={meta.description} />
        </Helmet>
        <div className="text_section">
          <div className="intro_text">
            <div class="profile-flexbox">
              <img class="profile_pic" src={introdata.your_img_url} />
            </div>
            <div className="intro mx-auto text-center">
              <h2
                className="mb-1x"
                style={{ fontSize: "6rem", fontWeight: "bold" }}
              >
                Jonathan Ma
              </h2>
              <h1
                className="fluidz-48 mb-1x"
                style={{ fontWeight: "normal", fontStyle: "italic" }}
              >
                {" "}
                {introdata.label} <BsWrenchAdjustable />
              </h1>
              <p className="intro-description mb-1x">
                Hi, I'm Jonathan. I enjoy learning about engineering, computer
                science, physics, and math. Check out my projects!
              </p>
            </div>
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
};
