import React from "react";
import "./style.css";
import { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import { TbCircuitResistor } from "react-icons/tb";
import { MdComputer } from "react-icons/md";
import { FaPlay, FaPause } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLayerGroup } from "react-icons/fa6";

import { dataportfolio, meta } from "../../content_option";

export const Portfolio = () => {
  const [portScrolled, setPortScrolled] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const [isAnimationActive, setIsAnimationActive] = useState(
    window.innerWidth > 600
  );

  useEffect(() => {
    function handleResize() {
      setIsAnimationActive(window.innerWidth > 1200);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const port = document.getElementById("portfolio");
    const header = document.getElementById("header");
    const home = document.getElementById("home");
    const projectBoxes = document.getElementById("projectBoxes");

    function handleScroll() {
      const portPos = port.getBoundingClientRect().top + window.scrollY;
      const headerHeight = header.getBoundingClientRect().height;
      const projectBoxesPos =
        projectBoxes.getBoundingClientRect().top + window.scrollY;
      console.log(projectBoxesPos);
      const homePos = home.getBoundingClientRect().top + window.scrollY;
      const headerPos = header.getBoundingClientRect().top + window.scrollY;

      // console.log(headerHeight + window.scrollY);
      if (!isAnimationActive) {
        // hardware.style.fontSize = window.innerWidth * 0.3;
        // software.style.fontSize = window.innerWidth * 0.3;

        return;
      }

      if (window.scrollY > portPos - 0.4 * window.innerHeight) {
        setPortScrolled(true);
        setOpacity(0);
        // console.log("true");
      } else {
        setPortScrolled(false);
        setOpacity(1);
        // console.log("false");
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isAnimationActive]);

  function centerText(portScrolled) {
    const hardware = document.querySelector(".portfolio-section-hardware");
    const software = document.querySelector(".portfolio-section-software");
    // const software = document.querySelector(".po")

    // Set up transitions
    hardware.style.transition =
      "transform 0.3s ease, font-size 0.1s ease-in-out";
    software.style.transition =
      "transform 0.3s ease, font-size 0.1s ease-in-out";

    const handleTransitionEnd = (event) => {
      if (event.propertyName === "font-size") {
        // Check if the font-size transition has ended
        const hardwareParentWidth = hardware.parentElement.offsetWidth;
        const softwareParentWidth = software.parentElement.offsetWidth;
        const hardwareWidth = hardware.offsetWidth;
        const softwareWidth = software.offsetWidth;
        const hardwareCenterOffset =
          hardwareParentWidth / 2 - hardwareWidth / 2;
        const softwareCenterOffset =
          softwareParentWidth / 2 - softwareWidth / 2;

        // Apply new transformations including the new centering
        hardware.style.transform = `translateX(${hardwareCenterOffset}px) `;
        software.style.transform = `translateX(${-softwareCenterOffset}px) `;

        // Optionally remove the listener if it's no longer needed after the adjustment
        hardware.removeEventListener("transitionend", handleTransitionEnd);
        software.removeEventListener("transitionend", handleTransitionEnd);
      }
    };

    if (portScrolled) {
      // Add event listener
      hardware.addEventListener("transitionend", handleTransitionEnd);
      software.addEventListener("transitionend", handleTransitionEnd);

      // Trigger style changes
      hardware.style.fontSize = "5rem"; // Increase font size
      // hardware.style.transform = "translateY(-10px)";
      software.style.fontSize = "5rem"; // Increase font size
      // software.style.transform = "translateY(-10px)";
    } else {
      // Reset styles
      hardware.style.fontSize = "3rem"; // Reset to original font size
      hardware.style.transform = "translateX(0) translateY(0)";
      software.style.fontSize = "3rem"; // Reset to original font size
      software.style.transform = "translateX(0) translateY(0)";

      // Clean up listener if no transition is going to happen
      hardware.removeEventListener("transitionend", handleTransitionEnd);
      software.removeEventListener("transitionend", handleTransitionEnd);
    }
  }

  useEffect(() => {
    centerText(portScrolled);
  }, [portScrolled]); // Dependency on portScrolled

  return (
    <div
      section
      id="portfolio"
      className="portfolio portfolio_background text-center"
    >
      <HelmetProvider>
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title> Portfolio | {meta.title} </title>{" "}
            <meta name="description" content={meta.description} />
          </Helmet>
          <Row className="mb-1 mt-1 pt-md-3">
            <h1
              className={`port_title display-4 mb-4 
              }`}
              style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
            >
              {" "}
              Projects{" "}
            </h1>
          </Row>
          <div className="arrow-grid-container">
            <div>
              <img
                className="hardware_arrow"
                src="red_arrow.png"
                style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
              ></img>
            </div>
            <div>
              <img
                className="software_arrow"
                src="red_arrow.png"
                style={{ opacity: opacity, transition: "opacity 0.5s ease" }}
              ></img>
            </div>
          </div>
          <div id="hardwareSoftware" className="title-grid-container">
            <div className="hardware">
              <h1 className={`mb-4 portfolio-section-hardware `}>
                Individual <TbCircuitResistor />
              </h1>
            </div>
            <div className="software">
              <h1
                className={`mb-4 portfolio-section-software
                }`}
              >
                Group <FaLayerGroup />
              </h1>
            </div>
          </div>

          <div id="projectBoxes" className="flex-container text-left">
            <div className="flex-column">
              <div className="grid-item">
                <h1 className="title">REM Sleep Detection Mask</h1>{" "}
                {/* FIRST PROJECT */}
                <p>
                  I engineered the first working prototype of this REM Sleep
                  Mask, designed to challenge traditional methods of sleep
                  monitoring. The mask uses
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    TCRT5000 infrared sensors{" "}
                  </span>
                  to detect rapid eye movements from the eyelids and an
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    MPU6050 accelerometer{" "}
                  </span>
                  to monitor head movements, providing an in-depth analysis of
                  sleep quality. I integrated a
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    Seeeduino Xiao microcontroller{" "}
                  </span>
                  for its compact size and efficient processing capabilities,
                  optimizing battery life by selecting a
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    400 mAh lithium-polymer battery{" "}
                  </span>
                  suited for overnight use. Components were
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    soldered{" "}
                  </span>
                  by hand for reliability and compactness, and I used
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    SolidWorks{" "}
                  </span>
                  to design a custom casing, ensuring safety and comfort.
                  Finally, I processed the collected data in
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    MATLAB{" "}
                  </span>
                  to interpret sleep patterns accurately, showcasing a full
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    embedded systems{" "}
                  </span>
                  approach to wearable sleep monitoring.
                </p>
                <p style={{ textAlign: "center" }}>
                  <a
                    href="https://docs.google.com/document/d/1FlFmRN61RvoswUo2MTeIusWtCggHVX3DisRtT5eIVoY/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#FF00FF",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "var(--umich-color)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#FF00FF")
                    }
                  >
                    Click here for full report
                  </a>
                </p>
                <Carousel>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/mask1.JPG"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p>
                        Components here show a Seeeduino XIAO microcontroller,
                        an MPU6050 accelerometer, a 400 mAH lithium-polymer
                        battery, and an SD card reader for data storage.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/mask2.JPG"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p>
                        Components here show TCRT5000 sensors to detect eye
                        movement and dim LED lights for more accurate sensor
                        detection. All components are indentented within a
                        custom designed 3D printed casing.
                      </p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/mask5.jpg"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p></p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/mask4.jpg"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p></p>
                    </Carousel.Caption>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      className="d-block w-100"
                      src="/mask6.jpg"
                      alt="First slide"
                    />
                    <Carousel.Caption>
                      <p></p>
                    </Carousel.Caption>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="grid-item">
                <h1 className="title">Smart Calorie Scale </h1>{" "}
                {/* FOURTH PROJECT */}
                <p>
                  I made the Smart Calorie Scale to assist in managing dietary
                  intake by accurately measuring and converting the weight of
                  food items into caloric content. Built around a precision{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    load cell
                  </span>{" "}
                  and powered by an{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    Arduino Nano
                  </span>
                  , the scale features a user-friendly button interface that
                  allows for easy selection of food types, conversion between
                  calories and grams, and use of a tare function. I created a
                  small database within the Arduino code, using a Food class
                  that stores properties like calories per gram for each food
                  type. Buttons trigger functions on the Nano, allowing
                  selection of food items and units for measurement. The device
                  was meticulously engineered using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    SolidWorks
                  </span>{" "}
                  for the design, followed by{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    3D printing
                  </span>{" "}
                  for a durable housing and a{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    custom-made circuit board
                  </span>{" "}
                  to support its functionality. I{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    soldered
                  </span>{" "}
                  the components by hand to ensure reliability and precision.
                  The scale is powered by AA batteries and includes an on/off
                  switch.
                  <p> </p>
                  <Carousel interval={null}>
                    <Carousel.Item>
                      {/* Only the "PRESS SCREEN FOR PLAY/PAUSE" text at the top */}
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          width: "100%",
                          textAlign: "center",
                          backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for readability
                          color: "#FF00FF",
                          padding: "10px 0",
                          fontWeight: "bold",
                          zIndex: 10,
                        }}
                      >
                        PRESS SCREEN FOR <FaPlay /> and <FaPause />
                      </div>

                      {/* Video Item */}
                      <video
                        className="d-block w-100"
                        controls
                        muted
                        style={{
                          maxHeight: "100vh", // Adjust the height to prevent overflow
                          width: "90%", // Adjust the width to leave space on the sides
                          margin: "0 auto", // Center the video horizontally
                          paddingRight: "30px", // Add padding on the right to avoid overlap with the arrow
                        }}
                      >
                        <source
                          src={`${process.env.PUBLIC_URL}/calorie3.mp4`}
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>

                      {/* Original caption at default position */}
                      <Carousel.Caption>
                        <p>
                          Select Food Item, Zero Scale, Measure Item Calories
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="/calorie1.jpg"
                        alt="picture of calorie scale"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="/calorie2.jpeg"
                        alt="picture of calorie scale"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="/calorie3.jpeg"
                        alt="picture of calorie scale"
                      />
                      <Carousel.Caption>
                        <p>Solidworks CAD</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="/calorie4.jpeg"
                        alt="picture of calorie scale"
                      />{" "}
                      <Carousel.Caption>
                        <p>PCB Schematic</p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </p>
              </div>
              <div className="grid-item">
                <h1 className="title">
                  Optimization of PVDF Piezoelectric Films
                </h1>{" "}
                <p>
                  In one of my research projects, I optimized the creation and
                  poling process of PVDF films to maximize their performance in
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    sensing{" "}
                  </span>{" "}
                  and
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    energy harvesting
                  </span>{" "}
                  applications. PVDF (Polyvinylidene Fluoride) films are
                  flexible, piezoelectric materials that generate an electric
                  charge in response to mechanical stress. I used specific
                  techniques such as fine-tuning film thickness, incorporating
                  ZnO nanoparticles to enhance phase alignment, and applying
                  corona poling under controlled temperature conditions for
                  precise dipole orientation. For data processing and analysis,
                  I leveraged
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    MATLAB
                  </span>
                  , allowing me to conduct
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    analytical modeling
                  </span>{" "}
                  and
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    {" "}
                    performance benchmarking
                  </span>{" "}
                  to quantify improvements. These optimizations resulted in over
                  a 100% increase in piezoelectric output compared to previous
                  lab benchmarks, demonstrating advancements in PVDF film
                  capabilities.
                </p>
                <p style={{ textAlign: "center" }}>
                  <a
                    href="https://docs.google.com/document/d/1u3WiQtSQ9WkwfuI6pq_PwVTCqMXkEXB2-9qe7bp7ch0/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#FF00FF",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "var(--umich-color)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#FF00FF")
                    }
                  >
                    Click here for full report
                  </a>
                </p>
              </div>
              <div className="grid-item">
                <h1 className="title"> C++ Based Projects</h1>{" "}
                <h1 className="subtitle">
                  {" "}
                  Relational Database
                  <a
                    href="https://github.com/jonnyma78/Relational-Database-Emulator"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginLeft: "10px", color: "magenta" }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "var(--umich-color)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#FF00FF")
                    }
                  >
                    <FaGithub />
                  </a>{" "}
                </h1>
                <p>
                  In this project, I created a relational database designed to
                  facilitate efficient{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    data storage
                  </span>
                  , manipulation, and retrieval for small-scale applications. I
                  created this project in C++ and utilized{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    data structures
                  </span>{" "}
                  such as{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    vectors
                  </span>
                  ,{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    hash tables
                  </span>
                  , and{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    binary search trees{" "}
                  </span>
                  to enable optimized indexing and searching. It provides a
                  command-line interface that supports creating tables,
                  inserting and deleting rows, and performing conditional
                  queries and join operations using a simplified SQL-like
                  syntax. By incorporating these data structures, this database
                  ensures quick access and efficient{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    data management
                  </span>{" "}
                  for a multitude of applications.
                </p>
                <div>
                  <h1 className="subtitle">
                    Maze Pathfinder{" "}
                    <a
                      href="https://github.com/jonnyma78/Maze-Path-Finder"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "10px", color: "magenta" }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.color = "var(--umich-color)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.color = "#FF00FF")
                      }
                    >
                      <FaGithub />
                    </a>{" "}
                  </h1>{" "}
                  <p>
                    In this project, I implemented efficient{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      pathfinding algorithms
                    </span>{" "}
                    using C++ to navigate a maze-like castle. I designed both{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      queue-based{" "}
                    </span>
                    and{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      stack-based{" "}
                    </span>
                    routing schemes to guide a character from a starting
                    position to a target destination while avoiding obstacles
                    and utilizing warp pipes for inter-room travel. The
                    queue-based scheme employed a{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      breadth-first search{" "}
                    </span>
                    to ensure the shortest path, while the stack-based scheme
                    used a{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      depth-first search{" "}
                    </span>
                    for potentially more memory-efficient exploration. This
                    project emphasized handling complex environments, ensuring
                    efficient execution for large maps, and providing accurate
                    and reliable navigation solutions within a specified time
                    constraint. This comprehensive approach enhanced my
                    understanding and application of fundamental data structures
                    and algorithms in practical scenarios.
                  </p>
                  <h1 className="subtitle">
                    Zombie Defense Game
                    <a
                      href="https://github.com/jonnyma78/Zombie-Defense-Game"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginLeft: "10px", color: "magenta" }}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.color = "var(--umich-color)")
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.color = "#FF00FF")
                      }
                    >
                      <FaGithub />
                    </a>{" "}
                  </h1>{" "}
                  <p>
                    In this project, I created a terminal-based zombie defense
                    game where you eliminate zombies using various{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      priority queue
                    </span>{" "}
                    data structures. The main goal was to implement efficient
                    data structures, including dynamic{" "}
                    <span
                      style={{
                        color: "var(--umich-color)",
                        fontWeight: "bold",
                      }}
                    >
                      polymorphism
                    </span>
                    , to manage zombie attributes like distance, speed, and
                    health. I designed each zombie as a class with specific
                    attributes and behaviors, utilizing priority queues to
                    determine the order of actions. I focused on developing my
                    skills in reading and implementing unfamiliar data
                    structures, debugging complex code, and optimizing
                    performance to handle the simulation's demands.
                  </p>
                </div>{" "}
              </div>
            </div>
            <div className="flex-column">
              <div className="grid-item">
                <h1 className="title">Four Bar Linkage </h1>{" "}
                {/* SECOND PROJECT */}
                <p>
                  For a robotics competition, my team and I designed and built a
                  high-speed four-bar linkage mechanism with optimized gears for
                  torque control. The linkage detects flags as they appear using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    ultrasonic sensors
                  </span>{" "}
                  that output precise distance measurements. Based on the
                  detected flag, the linkage directs a flashlight to activate a
                  photosensor assigned to that flag. Using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    SolidWorks
                  </span>
                  , my team and I carefully modeled the system to be lightweight
                  and applied{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    kinematics
                  </span>{" "}
                  principles to ensure the flashlight aligns geometrically with
                  each photosensor. I then fabricated each component using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    lathes
                  </span>
                  ,{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    mills
                  </span>
                  , and a{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    waterjet
                  </span>{" "}
                  for precision. I implemented{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    PID control
                  </span>
                  , using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    MATLAB
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    Simulink
                  </span>{" "}
                  to tune parameters and simulate responses. This setup
                  optimized the linkage’s speed and accuracy for quick
                  deployment, significantly boosting performance in competition.
                </p>
                <p style={{ textAlign: "center" }}>
                  <a
                    href="https://drive.google.com/file/d/1w0_S7I6OTQ-RyPnw-v-v9p0e1DAyb4bW/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#FF00FF",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "var(--umich-color)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#FF00FF")
                    }
                  >
                    Click here for full report
                  </a>
                </p>
                <video width="100%" controls muted>
                  <source
                    src={`${process.env.PUBLIC_URL}/linkage_demo.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div className="grid-item">
                <h1 className="title">Magnetic Levitation </h1>{" "}
                {/* FOURTH PROJECT */}
                <p>
                  The Magnetic Levitation System 33-210 uses controlled
                  electromagnetic forces to suspend a steel ball in mid-air. In
                  this project, my group and I designed and tested a control
                  system to levitate a ball in mid-air using magnetic forces.
                  This project involved analyzing the Maglev hardware’s dynamics
                  and designing a{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    PID controller
                  </span>{" "}
                  to achieve precise position control. Our approach included
                  modeling the system’s dynamics with a transfer function and
                  using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    Root Locus
                  </span>{" "}
                  methods to set key controller parameters, including
                  proportional, integral, and derivative gains, to stabilize the
                  system and minimize both overshoot and settling time. Using{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    MATLAB
                  </span>{" "}
                  and{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    Simulink
                  </span>
                  , we tested the PID controller on both a linearized and
                  nonlinear model of the system. The controller performed well
                  across different inputs. For example, we set the ball to
                  follow a series of step and square wave patterns, and the
                  controller maintained accurate tracking and fast stabilization
                  throughout.
                  <p> </p>
                </p>
                <p style={{ textAlign: "center" }}>
                  <a
                    href="https://docs.google.com/document/d/17f-dVzDoBpsn5KPr66Zrk1OI_E0ODGMS1HnELcQrmHI/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#FF00FF",
                      fontWeight: "bold",
                      textDecoration: "none",
                    }}
                    onMouseOver={(e) =>
                      (e.currentTarget.style.color = "var(--umich-color)")
                    }
                    onMouseOut={(e) =>
                      (e.currentTarget.style.color = "#FF00FF")
                    }
                  >
                    Click here for full report
                  </a>
                </p>
                <video
                  className="d-block w-100"
                  controls
                  muted
                  style={{
                    maxHeight: "100vh", // Adjust the height to prevent overflow
                    width: "90%", // Adjust the width to leave space on the sides
                    margin: "0 auto", // Center the video horizontally
                    paddingRight: "30px", // Add padding on the right to avoid overlap with the arrow
                  }}
                >
                  <source
                    src={`${process.env.PUBLIC_URL}/maglev3.mp4`}
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>{" "}
              <div className="grid-item">
                <h1 className="title">Robotics Competition RMP</h1>{" "}
                <p>
                  In this project, I collaborated with my team to create an RMP
                  (Robotic Mobility Platform) that we designed in{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    SolidWorks
                  </span>
                  {". "}I fabricated many of the components from raw aluminum
                  materials using precision tools, including a{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    mill
                  </span>
                  ,{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    lathe
                  </span>
                  , and{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    waterjet
                  </span>
                  . The platform’s structure included critical components such
                  as an **Upper Structure Bracket**, which securely connects the
                  arm to the base plate, and a **Belly Pan** that serves as the
                  main structural support, housing all other subsystems. A
                  **Driven Shaft** connects the wheel to the front wheel bracket
                  and constrains motion, while the **Arm** is gear-driven to
                  maximize torque and is specifically engineered to lift a flag
                  during competition tasks. For high accuracy, I applied{" "}
                  <span
                    style={{ color: "var(--umich-color)", fontWeight: "bold" }}
                  >
                    GD&T
                  </span>{" "}
                  principles, specifying precise dimensional tolerances and
                  surface finishes for each part. For example, the front wheel
                  bracket required tight tolerance levels for the bushing fits,
                  and the arm’s clearance holes ensured reliable pivoting. Each
                  component was manufactured with attention to tolerances,
                  roughness, and mass requirements.
                </p>
                <Carousel interval={null}>
                  <Carousel.Item>
                    {/* Only the "PRESS SCREEN FOR PLAY/PAUSE" text at the top */}
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        textAlign: "center",
                        backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional for readability
                        color: "#FF00FF",
                        padding: "10px 0",
                        fontWeight: "bold",
                        zIndex: 10,
                      }}
                    >
                      PRESS SCREEN FOR <FaPlay /> and <FaPause />
                    </div>

                    {/* Video Item */}
                    <video
                      className="d-block w-100"
                      controls
                      muted
                      style={{
                        maxHeight: "100vh", // Adjust the height to prevent overflow
                        width: "90%", // Adjust the width to leave space on the sides
                        margin: "0 auto", // Center the video horizontally
                        paddingRight: "30px", // Add padding on the right to avoid overlap with the arrow
                      }}
                    >
                      <source
                        src={`${process.env.PUBLIC_URL}/rmp_vid.mp4`}
                        type="video/mp4"
                      />
                      Your browser does not support the video tag.
                    </video>

                    {/* Original caption at default position */}
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="d-block w-100" src="/rmp.jpg" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="d-block w-100" src="/rmp2.jpg" />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img className="d-block w-100" src="/rmp3.jpg" />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>

            {/* <div>
              <h1 className="title">Euchre</h1>{" "}
            </div> */}
          </div>
        </div>
      </HelmetProvider>
    </div>
  );
};
