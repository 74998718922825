import React, { useEffect, useState } from "react";
// import { RiLightbulbFlashFill } from "react-icons/ri";
import { VscColorMode } from "react-icons/vsc";

//import { WiMoonAltWaningCrescent4 } from "react-icons/wi";

const Themetoggle = () => {
  const [theme, settheme] = useState(localStorage.getItem("theme"));
  const themetoggle = () => {
    settheme(theme === "dark" ? "light" : "dark");
  };
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
    localStorage.setItem("theme", theme);
  }, [theme]);
  return (
    <div className="align-items-center d-flex" style={{ fontSize: "2rem" }}>
      <VscColorMode className="custom_button" onClick={themetoggle} />
    </div>
  );
};

export default Themetoggle;
