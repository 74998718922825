import React from "react";
import "./style.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Container, Row, Col } from "react-bootstrap";

import { meta } from "../../content_option";

export const About = () => {
  return (
    <div className="about_background">
      <section id="about" className="about">
        <HelmetProvider>
          <Container className="About-header">
            <Helmet>
              <meta charSet="utf-8" />
              <title> About | {meta.title}</title>
              <meta name="description" content={meta.description} />
            </Helmet>
            <Row className="mb-5 mt-3 pt-md-3">
              <Col lg="8">
                <h1 className="display-4 mb-4">About me</h1>
                <hr className="t_border my-4 ml-0 text-left" />
              </Col>
            </Row>
            <Row className="sec_sp">
              <Col lg="5">
                <h3 className="color_sec py-4">About Myself</h3>
              </Col>
              <Col lg="7" className="d-flex align-items-center">
                <div>
                  <p>
                    I'm a Robotics Engineer with a focus on the intersection of
                    mechanical systems, machine learning, and control theory.
                    I'm passionate about leveraging machine learning and
                    advanced algorithms to enhance the performance and autonomy
                    of robotic systems. My interests lie in optimizing
                    mechanical design, implementing predictive models, and
                    integrating sensing technologies to create intelligent and
                    adaptive systems that push the boundaries of robotics and
                    automation.,
                  </p>
                </div>
              </Col>
            </Row>
            <Row className=" sec_sp">
              <Col lg="5">
                <h2 className="color_sec py-4">Education</h2>
              </Col>
              <Col lg="7">
                <h3 className="mb-3">
                  Master of Science in Engineering in{" "}
                  <span className="emphasize-major">Robotics</span>,{" "}
                  <span className="emphasize-upenn">
                    University of Pennsylvania
                  </span>
                </h3>

                <h3>
                  Bachelor of Science in Engineering in{" "}
                  <span className="emphasize-major">
                    Mechanical Engineering
                  </span>
                  ,
                  <span className="emphasize-umich">
                    {" "}
                    University of Michigan
                  </span>
                </h3>
              </Col>
            </Row>
            <Row className="sec_sp">
              <Col lg="5">
                <h3 className="color_sec py-4">Skills</h3>
              </Col>
              <Col lg="7" className="d-flex align-items-center">
                <div>
                  <p>
                    <span className="category-programming">
                      Programming Languages:
                    </span>{" "}
                    C/C++, MATLAB, Python, ROS, Java, JavaScript, HTML, CSS
                  </p>
                  <p>
                    <span className="category-software">Software: </span>
                    SolidWorks (Certified), Simulink, Arduino, OpenCV, Altium
                    Designer, Siemens NX, MSC Adams, Ansys, LabView
                  </p>
                  <p>
                    <span className="category-fabrication">Fabrication:</span>{" "}
                    GD & T, Soldering, 3D printing, Waterjet cutting, CNC
                    milling, Lathe
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="sec_sp">
              <Col lg="5">
                <h3 className="color_sec py-4">Notable Coursework</h3>
              </Col>
              <Col lg="7" className="d-flex align-items-center">
                <div className="course-list">
                  <ul className="two-columns">
                    <li>Control Systems Analysis and Design</li>
                    <li>Data Structures and Algorithms</li>
                    <li>Design and Manufacturing</li>
                    <li>Differential Equations</li>
                    <li>Dynamics and Vibrations</li>
                    <li>Electrical Circuits, Systems, and Applications</li>
                    <li>Fluid Mechanics</li>
                    <li>Machine Learning</li>
                    <li>Machine Perception</li>
                    <li>Matrix Algebra</li>
                    <li>Multivariable and Vector Calculus</li>
                    <li>Robotic Kinematics and Dynamics</li>
                    <li>Statics and Solid Mechanics</li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </HelmetProvider>
      </section>
    </div>
  );
};
{
  /* <p className="intro-description mb-1x">
                Hi, I'm Jonathan. I am currently pursuing my Master of Science
                in Engineering in{" "}
                <span className="emphasize-major">Robotics </span> at the{" "}
                <span className="emphasize-upenn">
                  University of Pennsylvania.
                </span>
              </p>
              <p className="mb-1x">
                I hold a Bachelor of Science in Engineering in{" "}
                <span className="emphasize-major">Mechanical Engineering</span>{" "}
                with a minor in{" "}
                <span className="emphasize-major">Computer Science</span> from
                the{" "}
                <span className="emphasize-umich">University of Michigan</span>.
              </p> */
}
