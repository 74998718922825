import React, { useEffect, useState } from "react";
import "./style.css";
import { Nav, Navbar, Container } from "react-bootstrap";
import { BsWrenchAdjustableCircleFill } from "react-icons/bs";

import Themetoggle from "../components/themetoggle";

function Headermain() {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    // console.log("running useeffect");
    function handleScroll() {
      if (
        window.scrollY >
        0.025 * document.body.getBoundingClientRect().height
      ) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleNavLinkClick(event, targetId) {
    event.preventDefault(); //prevents default href behavior
    const targetElement = document.getElementById(targetId); //get the DOM element by the targetID
    const header = document.getElementById("header");

    let offset = window.innerHeight * 0.05;
    if (targetId === "home") {
      offset = window.innerHeight * 0.07;
    }

    const bodyRect = document.body.getBoundingClientRect().top; //this gets the top position of the body element
    const elementRect = targetElement.getBoundingClientRect().top; //this gets the top position of the TARGET element

    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  return (
    // <section id="header" className="header">
    <>
      <Navbar
        id="header"
        className={`top-bar fixed-top header ${scrolled ? "scrolled" : ""}`}
      >
        <Container className="container-border d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-start align-items-center flex-1">
            <BsWrenchAdjustableCircleFill style={{ fontSize: "2rem" }} />
          </div>
          <div className="container-border d-flex flex-6 justify-content-between">
            <Nav.Link
              href="#home"
              onClick={(e) => handleNavLinkClick(e, "home")}
              className="nav-link"
            >
              Home
            </Nav.Link>
            <Nav.Link
              href="#portfolio"
              onClick={(e) => handleNavLinkClick(e, "hardwareSoftware")}
              className="nav-link"
            >
              Projects
            </Nav.Link>
            <Nav.Link
              href="#about"
              onClick={(e) => handleNavLinkClick(e, "about")}
              className="nav-link"
            >
              About
            </Nav.Link>
            <Nav.Link
              href="#contact"
              onClick={(e) => handleNavLinkClick(e, "contact")}
            >
              Contact
            </Nav.Link>
          </div>
          <div className="d-flex flex-1">
            <div className="d-flex flex-3 container-border"></div>
            <Nav.Link className="custom_button d-flex flex-1 align-items-center justify-content-end container-border">
              <Themetoggle className="custom_button" />
            </Nav.Link>
          </div>

          {/* <button
            onClick={Themetoggle}
            className="custom-button d-flex flex-1 align-items-center justify-content-end"
          >
            <FaLightbulb style={{ fontSize: "2rem" }} />
          </button> */}
        </Container>
      </Navbar>
    </>
    // </section>
  );
}

export default Headermain;
